module.exports = {
  defaultTitle: 'Anupam Dagar',
  logo: 'https://portfolio.anupamdagar.com/icons/favicon-512.png',
  author: 'Anupam Dagar',
  url: 'https://portfolio.anupamdagar.com',
  legalName: 'Anupam Dagar',
  defaultDescription: 'I’m Anupam Dagar and I’m a Full Stack Developer.',
  socialLinks: {
    github: 'https://github.com/Anupam-dagar',
    linkedin: 'https://www.linkedin.com/in/anupamdagar/',
  },
  googleAnalyticsID: 'UA-162969672-1',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    twitter: '@anupamdagr',
  },
  contact: {
    email: 'dagaranupam@gmail.com',
  },
  foundingDate: '2018',
};
